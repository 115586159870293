(function () {
	// First party cookie as anchor for tracking
	const cookieStorage = {
		getItem: (item) => {
			const cookies = document.cookie
				.split(';')
				.map((cookie) => cookie.split('='))
				.reduce(
					(acc, [key, value]) => ({
						...acc,
						[key.trim()]: value,
					}),
					{}
				);
			return cookies[item];
		},
		setItem: (item, value) => {
			document.cookie = `${item}=${value};`;
		},
	};

	const storageType = cookieStorage;
	const consentPropertyName = 'yobt_consent';
	const shouldShowPopup = () => !storageType.getItem(consentPropertyName);
	const saveToStorage = () => storageType.setItem(consentPropertyName, true);
	const consentPopup = document.getElementById('consent-popup');

	window.onload = () => {
		const acceptFn = (event) => {
			saveToStorage(storageType);
			consentPopup.classList.add('hidden');
			try {
				dataLayer.push({ event: 'cookie-consent' });
			} catch (error) {
				// Renamed from 'e' to 'error'
				console.log(error.message);
			}
		};

		const acceptBtn = document.getElementById('accept');
		acceptBtn.addEventListener('click', acceptFn);

		if (shouldShowPopup()) {
			// Removed redundant argument
			setTimeout(() => {
				consentPopup.classList.remove('hidden');
				consentPopup.classList.add('animate__animated', 'animate__fadeInDown');
			}, 1000);
		}

		// Make Medizinisches EMS-Training active on pageload
		const medEmsElement = document.querySelector('.med-ems');
		if (medEmsElement) {
			medEmsElement.classList.add('active');
			setTimeout(() => {
				medEmsElement.classList.remove('active');
			}, 5000);
		}
	};

	// Store div content to variables
	const first = `<h3 class>Ganzkörper EMS-Training</h3>
    <p>Nutzen Sie diese evidenzbasierte Methode für Prävention und Therapie als Ihren Einstieg in eine sichere und nachhaltige Zukunft – auch als Quereinsteiger.</p>`;
	const second = `<h3>Endlich etwas Eigenes</h3>
    <p>Gründen Sie ihr Unternehmen im Zukunftsmarkt Gesundheit!</p>`;
	const last = `<h3>Zuverlässige Partner</h3>
    <p>Vertrauen Sie auf die jahrelange Erfahrung und auf das starke Netzwerk der Marktführer.</p>`;
	const secondLast = `<h3>Your Own Big Thing</h3>
    <p>Wenn Sie Wert auf einen gesunden Lebensstil legen und sich nicht als den „typischen Angestellten“ sehen - dann starten Sie jetzt!</p>`;

	// Swap div content so partners will display last on mobile devices
	if (window.innerWidth > 1200) {
		const firstElement = document.querySelector('.first');
		const secondElement = document.querySelector('.second');
		const lastElement = document.querySelector('.last');
		const secondLastElement = document.querySelector('.second-last');

		if (firstElement && secondElement && lastElement && secondLastElement) {
			firstElement.innerHTML = second;
			firstElement.classList.remove('med-ems');
			secondElement.innerHTML = first;
			secondElement.classList.add('med-ems');
			lastElement.innerHTML = secondLast;
			lastElement.classList.remove('partners');
			secondLastElement.innerHTML = last;
			secondLastElement.classList.add('partners');
		}
	} else {
		const firstElement = document.querySelector('.first');
		const secondElement = document.querySelector('.second');
		const lastElement = document.querySelector('.last');
		const secondLastElement = document.querySelector('.second-last');

		if (firstElement && secondElement && lastElement && secondLastElement) {
			firstElement.innerHTML = first;
			firstElement.classList.add('med-ems');
			secondElement.innerHTML = second;
			secondElement.classList.remove('med-ems');
			lastElement.innerHTML = last;
			lastElement.classList.add('partners');
			secondLastElement.innerHTML = secondLast;
			secondLastElement.classList.remove('partners');
		}
	}

	// Highlight sponsors on partner mouse entry
	const partnersElement = document.querySelector('.partners');
	if (partnersElement) {
		partnersElement.addEventListener('mouseenter', (event) => {
			// Renamed from 'e' to 'event'
			const sponsorsContainer = document.querySelector('.sponsors-container');
			if (sponsorsContainer) {
				sponsorsContainer.classList.add('animate__animated', 'animate__pulse');
			}
		});

		partnersElement.addEventListener('mouseleave', (event) => {
			// Renamed from 'e' to 'event'
			const sponsorsContainer = document.querySelector('.sponsors-container');
			if (sponsorsContainer) {
				sponsorsContainer.classList.remove('animate__animated', 'animate__pulse');
			}
		});
	}
})();
